import React, { useEffect, useState } from 'react'

import Layout from '../../Layout/Layout'
import Breadcrumb from '../../common/Breadcrumb'
import AnalyticsService from '../../../services/analytics'
import { API_BASE_URL, API_KEY, STATIC_SERVER_URL } from 'config/config';
import { v4 as uuidv4 } from 'uuid';
import Spinner from 'components/common/Spinner/Spinner';

function PressRegistration() {
  const [form, setForm] = useState({
    match_id: '',
    accreditation_type: '1',
    name: '',
    media_name: '',
    email: '',
    place_of_birth: '',
    date_of_birth: '',
    licence_plate: '',
  });

  const [errors, setErrors] = useState({
    name_error: '',
    email_error: '',
    media_name_error: '',
    place_of_birth_error: '',
    date_of_birth_error: '',
  });

  const [subscriptionSuccess, setSubscriptionSuccess] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [nextFixture, setNextFixture] = useState([]);

  useEffect(() => {
    AnalyticsService.sendPageView(window.location.pathname);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    const fetchMatches = () => {
      fetch(`${API_BASE_URL}/fixtures/next`, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'X-API-KEY': API_KEY,
          'X-Request-ID': uuidv4()
        }
      })
        .then((res) => res.json())
        .then(
          (json) => {
            const nextFixture = json.slice(0, 1);
            setForm((prevForm) => ({
              ...prevForm,
              match_id: nextFixture[0].id,
            }));
            setNextFixture(nextFixture);
          },
          (error) => {
            setIsLoaded(true);
          },
        );
    };

    fetchMatches();
  }, []);

  const validate = () => {
    let isValid = true;
    const newErrors = {
      name_error: '',
      media_name_error: '',
      email_error: '',
      place_of_birth_error: '',
      date_of_birth_error: '',
    };

    if (form.name.length < 3) {
      newErrors.name_error = 'Helytelen név!';
      isValid = false;
    }

    if (form.media_name.length < 3) {
      newErrors.media_name_error = 'Helytelen média név!';
      isValid = false;
    }

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(form.email).toLowerCase())) {
      newErrors.email_error = 'Helytelen e-mail cím!';
      isValid = false;
    }

    const reDate = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
    if (!reDate.test(String(form.date_of_birth))) {
      newErrors.date_of_birth_error = 'Helytelen születési dátum!';
      isValid = false;
    }

    if (form.place_of_birth.length < 3) {
      newErrors.place_of_birth_error = 'Helytelen születési hely!';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      setSubmitDisabled(true);
      setIsLoaderActive(true);

      try {
        const response = await fetch(`${API_BASE_URL}/press-accreditation`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(form),
        });

        const isSuccess = await response.json();
        console.log(isSuccess);
        setSubscriptionSuccess(isSuccess);

        if (isSuccess) {
          setForm({
            name: '',
            media_name: '',
            email: '',
            date_of_birth: '',
            place_of_birth: '',
            licence_plate: '',
          });
        }
      } catch (error) {
        console.error(error);
        setSubscriptionSuccess(false);
      } finally {
        setSubmitDisabled(false);
        setIsLoaderActive(false);
      }
    } else {
      console.log('Hibás űrlap!');
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    setForm((prevForm) => ({
      ...prevForm,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  return (
    <Layout type="slim" cssClassName="article-container">
      <section
        className="main-content register-background"
        style={{
          backgroundImage: `url(${STATIC_SERVER_URL}/web/images/draft/bozsik-stadion-szekek.jpg)`,
        }}
      >
        <div className="container register-container">
          <div className="col-12">
            <h2 className="h2">Sajtó regisztráció</h2>
          </div>
          <div className="col-12">
            {subscriptionSuccess === true && (
              <div className="login-success-message">
                Sikeresen leadta akkreditációját!
              </div>
            )}
            {subscriptionSuccess === false && (
              <div className="login-error-message">
                Hiba az akkreditáció leadása közben.
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="checkbox-header">
                  Melyik mérkőzésre szeretne akkreditálni?&nbsp;<span>*</span>
                </div>
                <div className="form-group col-md-12">
                  {nextFixture.map((fixture) => (
                    <div className="form-check" key={fixture.id}>
                      <input
                        type="radio"
                        className="form-check-input"
                        id={`match_id_${fixture.id}`}
                        name="match_id"
                        value={fixture.id}
                        checked={Number(form.match_id) === fixture.id}
                        onChange={handleChange}
                      />
                      <label htmlFor={`match_id_${fixture.id}`} className="form-check-label">
                        {fixture.home_squad_name} - {fixture.away_squad_name} ({fixture.event_date})
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="form-row">
                <div className="checkbox-header">
                  Akkreditáció típusa&nbsp;<span>*</span>
                </div>
                <div className="form-group col-md-12 no-margin">
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="accreditation_type_1"
                      name="accreditation_type"
                      value="1"
                      checked={form.accreditation_type === "1"}
                      onChange={handleChange}
                    />
                    <label htmlFor="accreditation_type_1" className="form-check-label">
                      újságíró
                    </label>
                  </div>
                </div>

                <div className="form-group col-md-12">
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="accreditation_type_2"
                      name="accreditation_type"
                      value="2"
                      checked={form.accreditation_type === "2"}
                      onChange={handleChange}
                    />
                    <label htmlFor="accreditation_type_2" className="form-check-label">
                      fotós
                    </label>
                  </div>
                </div>

              </div>

              <div className="form-row">
                <div className="form-group col-md-12">
                  <label htmlFor="name">
                    Név
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={form.name}
                    onChange={handleChange}
                  />
                  <div className="validation_error">{errors.name_error}</div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-12">
                  <label htmlFor="media_name">
                    Média megnevezése
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="media_name"
                    name="media_name"
                    value={form.media_name}
                    onChange={handleChange}
                  />
                  <div className="validation_error">{errors.media_name_error}</div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="place_of_birth">
                    Születési hely
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="place_of_birth"
                    name="place_of_birth"
                    value={form.place_of_birth}
                    onChange={handleChange}
                  />
                  <div className="validation_error">{errors.place_of_birth_error}</div>
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="date_of_birth">
                    Születési dátum
                    <span>*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="date_of_birth"
                    name="date_of_birth"
                    value={form.date_of_birth}
                    onChange={handleChange}
                  />
                  <div className="validation_error">{errors.date_of_birth_error}</div>
                </div>

              </div>

              <div className="form-row">
                <div className="form-group col-md-12">
                  <label htmlFor="email">
                    E-mail cím
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    value={form.email}
                    onChange={handleChange}
                  />
                  <div className="validation_error">{errors.email_error}</div>
                </div>
              </div>

              {form.accreditation_type === '2' && (
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label htmlFor="licence_plate">
                      Fotós akkreditáció esetén, kérjük adja meg rendszámát!
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="licence_plate"
                      name="licence_plate"
                      value={form.licence_plate}
                      onChange={handleChange}
                    />
                    <div className="validation_error">{errors.licence_plate_error}</div>
                  </div>
                </div>
              )}

              <div className="text-center">
                <button disabled={submitDisabled} type="submit" className="btn btn-primary">
                  Regisztrálok
                </button>
                {isLoaderActive && <Spinner />}
              </div>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PressRegistration;
