import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import { NEWS_CATEGORIES, BASE_HREF, STATIC_SERVER_URL } from '../../../config/config';
import RecommendedNews from './RecommendedNews';
import DateFormater from '../../common/DateFormater';
import BannerContainer from '../../common/Banner/BannerContainer';
import ArticleImage from './ArticleImage';
import { useRecoilValue } from 'recoil';
import { windowWidth } from 'atoms';
import ArticleGallery from './ArticleGallery/ArticleGallery';
import ReactDOM from 'react-dom/client';

function ArticleContent(props) {
  const appWindowWidth = useRecoilValue(windowWidth);

  const articleRef = useRef();
  const rootsMap = useRef(new Map());

  useEffect(() => {
    const container = articleRef.current;

    const elements = Array.from(container.querySelectorAll('[data-component]'));

    elements.forEach((element) => {
      const componentName = element.getAttribute('data-component');
      if (componentName === 'gallery') {
        const galleryId = element.getAttribute('data-gallery-id');

        if (!rootsMap.current.has(element)) {
          const root = ReactDOM.createRoot(element);
          rootsMap.current.set(element, root);
        }

        const root = rootsMap.current.get(element);
        root.render(
          <ArticleGallery galleryId={galleryId} slug={props.slug} uploadDate={props.uploadDate} />
        );
      }
    });

    return () => {
      rootsMap.current.forEach((root, element) => {
        Promise.resolve().then(() => {
          root.unmount();
          rootsMap.current.delete(element);
        });
      });
    };
  }, [props.content]);

  return (
    <div>
      {appWindowWidth < 768 && (
        <ArticleImage title={props.title} category={props.category} photoName={props.photoName} />
      )}
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="article">
              <div className="article-header">
                <span className="publish-date">
                  <span>Megjelenés:&nbsp;</span>
                  <DateFormater dateTime={props.publishDate} />
                </span>
                <span className="read-time">
                  Olvasási idő: {Math.ceil(props.content.split(' ').length / 300)} perc
                </span>
                <span className="publisher">{props.publisher}</span>
                <span className="email-share">
                  <a
                    target="_blank"
                    href={`mailto:?subject=Cikkajánlás&body=${BASE_HREF}/hirek/${
                      props.slug
                    }/${moment(props.uploadDate).format('YYYY-MM-DD')}`}
                    rel="noreferrer"
                  >
                    <img
                      src={`${STATIC_SERVER_URL}/web/images/draft/email_share.png`}
                      alt="küldés e-mailben"
                    />
                  </a>
                </span>
                <span className="social-share">
                  <a
                    target="_blank"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${BASE_HREF}/hirek/${
                      props.slug
                    }/${moment(props.uploadDate).format('YYYY-MM-DD')}`}
                    rel="noreferrer"
                  >
                    <img
                      src={`${STATIC_SERVER_URL}/web/images/draft/facebook_share.png`}
                      alt="megosztás"
                    />
                  </a>
                </span>
              </div>
              <h3 className="text-left">{props.lead}</h3>
              <div
                ref={articleRef}
                className="text-left article-content"
                dangerouslySetInnerHTML={{ __html: props.content }}
              />
              <div className="article-footer">
                <DateFormater dateTime={props.publishDate} />
                <span>
                  <Link to={`/hirek-listazas/${NEWS_CATEGORIES[props.category]}`}>
                    {NEWS_CATEGORIES[props.category]}
                  </Link>
                </span>
                <span className="email-share">
                  <a
                    target="_blank"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${BASE_HREF}/hirek/${
                      props.slug
                    }/${moment(props.uploadDate).format('YYYY-MM-DD')}`}
                    rel="noreferrer"
                  >
                    <img
                      src={`${STATIC_SERVER_URL}/web/images/draft/email_share.png`}
                      alt="küldés e-mailben"
                    />
                  </a>
                </span>
                <span className="social-share">
                  <a
                    target="_blank"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${BASE_HREF}/hirek/${
                      props.slug
                    }/${moment(props.uploadDate).format('YYYY-MM-DD')}`}
                    rel="noreferrer"
                  >
                    <img
                      src={`${STATIC_SERVER_URL}/web/images/draft/facebook_share.png`}
                      alt="megosztás"
                    />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BannerContainer type="3" cssClassName="" />
      <div className="container">
        <div className="recommended-news-container">
          <h2 className="recommended-news-header">Kapcsolódó hírek</h2>
          <RecommendedNews />
        </div>
      </div>
    </div>
  );
}

export default ArticleContent;
