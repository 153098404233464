import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { hotjar } from 'react-hotjar';
import { HOTJAR } from 'config/config';
import { RecoilRoot } from 'recoil';

import Article from './components/Pages/Article/Article';
import NewsList from './components/Pages/NewsList/NewsList';
import StaticContent from './components/Pages/StaticContent/StaticContent';
import Roster from './components/Pages/Roster/Roster';
import Frontpage from './components/Pages/Frontpage/Frontpage';
import Archive from './components/Pages/Archive/Archive';
import NewsLetter from './components/Pages/NewsLetter/NewsLetter';
import Page404 from './components/common/Page404';
import PhotoAlbumList from './components/Pages/PhotoAlbumList/PhotoAlbumList';
import PhotoAlbumDetails from './components/Pages/PhotoAlbumDetails/PhotoAlbumDetails';
import PhotoGallery from './components/Pages/PhotoGallery/PhotoGallery';
import PlayerDetails from './components/Pages/Roster/PlayerDetails';
import PrizeGame from './components/Pages/PrizeGame/PrizeGame';
import Register from './components/auth/register/Register';
import Results from './components/Pages/Results/Results';
import StandingsReserves from './components/Pages/StandingsReserves/StandingsReserves';
import ValidateCode from './components/auth/validate-code/ValidateCode';
import Videos from './components/Pages/Videos/Videos';
import AnalyticsService from './services/analytics';
import * as ConsentService from './services/consent';
import ParkingReservation from './components/Pages/ParkingReservation/ParkingReservation';
import MatchCentre from './components/Pages/match-centre/MatchCentre';
import Management from 'components/Pages/Management/Management';
import PlayList from 'components/Pages/PlayList/PlayList';
import Report from 'components/Pages/Report/Report';

import './sass/app.scss';
import PressRegistration from 'components/Pages/PressRegistration/PressRegistration';

function App() {

  if (ConsentService.getLevel() >= 2) {
    AnalyticsService.initialize(process.env.REACT_APP_ANALYTICS_ID);
    hotjar.initialize(HOTJAR.id, HOTJAR.sv);
  }

  return (
    <div>
      <RecoilRoot>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Frontpage />} />
            <Route path="/legfrissebb" element={<Frontpage />} />
            <Route path='/online-kozvetites/:id' element={<MatchCentre />} />
            <Route path="/hirek/:slug/:uploaded" element={<Article />} />
            <Route path="/hirek-listazas/:id" element={<NewsList />} />
            <Route path="/tartalom/:id" element={<StaticContent />} />
            <Route path="/csapatok/:id" element={<Roster />} />
            <Route path="/vezetoseg" element={<Management />} />
            <Route path="/jatekos-adatlap/:id" element={<PlayerDetails />} />
            <Route path="/utanpotlas-tabella/:id" element={<StandingsReserves />} />
            <Route path="/merkozesek/:squad" element={<Results />} />
            <Route path="/media/videok" element={<Videos />} />
            <Route path="/media/sajto-regisztracio" element={<PressRegistration />} />
            <Route path="/media/:id" element={<PlayList />} />
            <Route path="/tv" element={<Videos />} />
            <Route path="/fotoalbumok" element={<PhotoAlbumList />} />
            <Route path="/media/fotoalbumok" element={<PhotoAlbumList />} />
            <Route path="/galeria/:id/:photo" element={<PhotoGallery />} />
            <Route path="/jegyzokonyv/:id" element={<Report />} />
            <Route path="/archivum" element={<Archive />} />
            <Route path="/hirek" element={<Archive />} />
            <Route path="/archivum/:id" element={<Archive />} />
            <Route path="/regisztracio" element={<Register />} />
            <Route path="/hirlevel" element={<NewsLetter />} />
            <Route path="/nyeremenyjatek" element={<PrizeGame />} />
            <Route path="/parkolofoglalas" element={<ParkingReservation />} />
            <Route path="/parkolo-foglalas" element={<ParkingReservation />} />
            <Route path="/validate-code/:id" element={<ValidateCode />} />
            <Route path="/404" element={<Page404 />} />
            <Route path="*" element={<Page404 />} />
            <Route element={<Page404 />} />
          </Routes>
        </BrowserRouter>
      </RecoilRoot>
    </div>
  );
}

export default App;
