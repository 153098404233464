import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';

import { v4 as uuidv4 } from 'uuid';
import { API_BASE_URL, API_KEY, BASE_HREF, STATIC_SERVER_URL } from '../../../../config/config';
import Loader from '../../../common/Loader';

import './articleGallery.scss';
import { Link } from 'react-router-dom';

function ArticleGallery(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [folder, setFolder] = useState('');
  const [season, setSeason] = useState('');
  const [title, setTitle] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [photoItems, setPhotoItems] = useState([]);
  const [itemsNumber, setItemsNumber] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetch(`${API_BASE_URL}/photo-album/details?id=${props.galleryId}`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Something went wrong');
      })
      .then(
        (json) => {
          setItemsNumber(json.photolist.length);
          setPhotoItems(json.photolist.splice(0, 5));
          setIsLoaded(true);
          setError(false);
          setFolder(json.folder);
          setSeason(json.season);
          setTitle(json.title);
          setCreatedAt(moment(json.created_at).format('YYYY-MM-DD'));
        },
        (error) => {
          setPhotoItems([]);
          setIsLoaded(true);
          setError(true);
        },
      );
  }, [props.galleryId]);

  if (error) {
    return <div>Hiba történt!</div>;
  } if (!isLoaded) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div className="article-gallery">
      <div className="headline">
        <h2>
          {title}
        </h2>
        <p className="date">
          Létrehozva: {createdAt}
        </p>
      </div>

      <div className="thumbnails">
        {photoItems.map((value, index) => (
          <figure key={index} id={`photo-thumbnail-${index}`}>
            {index === 4 && (
              <a href={`/galeria/${props.galleryId}/${index}`}>
                <img src={`${STATIC_SERVER_URL}/fototar/${season}/${folder}/${value}`} alt="" />
                <div className='last-thumbnail'>
                  <p>+{itemsNumber - 4}</p>
                </div>
              </a>
            )}

            {index !== 4 && (
              <a href={`/galeria/${props.galleryId}/${index}`}>
                <img src={`${STATIC_SERVER_URL}/fototar/${season}/${folder}/${value}`} alt="" />
              </a>
            )}

          </figure>
        ))}
      </div>
    </div>
  );
}

export default ArticleGallery;
