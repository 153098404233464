import { useParams } from 'react-router-dom';
import { STATIC_SERVER_URL } from '../../../config/config';
import '../../../sass/components/featured-news.scss';

function RosterSquadImage() {
  const { id } = useParams();

  return (
    <section id="featured-news">
      <div>
        <div>
          <h2>
            {id === 'elso-csapat'
              ? 'Első csapat'
              : id === 'masodik-csapat'
              ? 'Második csapat'
              : 'Női csapat'}
          </h2>
          <figure id="wallpaper">
            {id === 'elso-csapat' && (
              <img
                src={`${STATIC_SERVER_URL}/web/images/draft/roster-background-placeholder.jpg`}
                alt="Csapatkép"
              />
            )}
            {id !== 'elso-csapat' && (
              <img
                src={`${STATIC_SERVER_URL}/web/images/draft/roster-background-placeholder.jpg`}
                alt="Csapatkép"
              />
            )}
          </figure>
          <div className="shadow" />
        </div>
      </div>
    </section>
  );
}

export default RosterSquadImage;
